import React from 'react'
import {
  Container,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout'
import { OfferContent } from '../../../components/site/offer'

const WatchPage = ({ location }) => {
  const videoId = 'NXb2HuMeB-o'
  const offer = 'secrets_webinar'

  const Headline = () => {
    return (
      <Section>
        <Container className="mb-8 container-lg">
          <h1 className="mt-12 mb-4 text-3xl text-center">
            Secure Mobile Apps Without Exposing API Keys
          </h1>
          <h3 className="mt-4 mb-2 text-xl text-center text-gray-700">
            Webinar Recording: 9 June 2022
          </h3>
        </Container>
      </Section>
    )
  }

  const Intro = () => {
    return (
      <>
        <p>
          Michael Sampson, senior analyst at Osterman Research says “Upcoming
          Osterman findings show that mobile apps depend on average on more than
          30 third-party APIs, and that half of the mobile developers we
          surveyed are still storing API keys in the app code. These two things
          together constitute a massive attack surface for bad actors to
          exploit. And third-party API threats against mobile apps aren’t as
          well understood by companies as they should be.“
        </p>
        <p>
          This webinar will employ a live demo to show it is possible to avoid
          storing secrets in the mobile app code completely, eliminating any
          risk of extraction through code analysis, as well as the risk of
          exposure through accidental source code repository leaks.
        </p>
      </>
    )
  }

  const Action = () => {
    return (
      <>
        <p>
          Approov can help your business secure its mobile APIs quickly and
          effectively. Want to learn more about how we helped dozens of
          companies ensure that only their mobile apps can use their APIs? Talk
          to us.
        </p>

        <div className="flex flex-col items-center">
          <LinkButton
            className="button-secondary button-lg"
            href="/product/consult"
          >
            Talk to an Expert
          </LinkButton>
        </div>
      </>
    )
  }

  return (
    <PageLayout pathname={location.pathname}>

      <Headline />

      <OfferContent offer={offer}>
        <Section>
          <Container className="mb-8 scroll-mt-32">
            <Intro />
          </Container>

          <Container className="pt-0 pb-4 ">
            <div className="flex items-center justify-center w-full">
              <div className="w-full border border-gray-300 aspect-w-16 aspect-h-9">
                <iframe
                  title="Best Practices for Secure Access of 3rd Party APIs from Mobile Apps"
                  src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Container>

          <Container className="mb-8">
            <Action />
          </Container>
        </Section>
      </OfferContent>
    </PageLayout>
  )
}

export default WatchPage
